import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "react-app-polyfill/stable"; // For stable polyfills
import "react-app-polyfill/ie9";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "./index.css";
import "normalize.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { Provider } from "react-redux";
import store from "./state/Store";
import PreLoader from "./utlis/PreLoader";
import Error from "./pages/error/Error";
import Products from "./pages/products/Products";
import ProductsEdit from "./pages/admin/ProductsEdit";
const Root = React.lazy(() => import("./pages/root/Root"));
const Home = React.lazy(() => import("./pages/home/Home"));
const About = React.lazy(() => import("./pages/about/About"));
const Shop = React.lazy(() => import("./pages/shop/Shop"));
const Contact = React.lazy(() => import("./pages/contact/Contact"));
const WirelessProducts = React.lazy(() =>
  import("./pages/solutions/WirelessProducts")
);
const SwitchesAndDevices = React.lazy(() =>
  import("./pages/solutions/SwitchesAndDevices")
);
const NetworkCabling = React.lazy(() =>
  import("./pages/solutions/NetworkCabling")
);
const SmartSurveillance = React.lazy(() =>
  import("./pages/solutions/SmartSurveillance")
);
const FiberNetwork = React.lazy(() => import("./pages/solutions/FiberNetwork"));
const TimeAttendance = React.lazy(() =>
  import("./pages/solutions/TimeAttendance")
);
const VideoIntercom = React.lazy(() =>
  import("./pages/solutions/VideoIntercom")
);
const Storage = React.lazy(() => import("./pages/solutions/Storage"));
const WebDesign = React.lazy(() => import("./pages/solutions/WebDesign"));
const SolutionsData = React.lazy(() =>
  import("./pages/solutions/SolutionsData")
);
const Product = React.lazy(() => import("./pages/product/Product"));
const Support = React.lazy(() => import("./pages/support/Support"));
const Admin = React.lazy(() => import("./pages/admin/Admin"));
const AdminCategory = React.lazy(() => import("./pages/admin/AdminCategory"));
const Login = React.lazy(() => import("./pages/login/Login"));
const AdminProducts = React.lazy(() => import("./pages/admin/AdminProducts"));
const AdminPartners = React.lazy(() => import("./pages/admin/AdminPartners"));
const AdminEvent = React.lazy(() => import("./pages/admin/AdminEvent"));
const AdminContacts = React.lazy(() => import("./pages/admin/AdminContacts"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: (
          <Suspense fallback={<PreLoader />}>
            <Home />
          </Suspense>
        ),
      },

      {
        path: "/about",
        element: (
          <Suspense fallback={<PreLoader />}>
            <About />
          </Suspense>
        ),
      },
      {
        path: "/shop",
        element: (
          <Suspense fallback={<PreLoader />}>
            <Shop />{" "}
          </Suspense>
        ),
      },
      {
        path: "/product/:id",
        element: (
          <Suspense fallback={<PreLoader />}>
            <Product />{" "}
          </Suspense>
        ),
      },
      {
        path: "/products",
        element: (
          <Suspense fallback={<PreLoader />}>
            <Products />{" "}
          </Suspense>
        ),
      },
      {
        path: "/support",
        element: (
          <Suspense fallback={<PreLoader />}>
            <Support />{" "}
          </Suspense>
        ),
      },
      {
        path: "/contact",
        element: (
          <Suspense fallback={<PreLoader />}>
            <Contact />{" "}
          </Suspense>
        ),
      },
      {
        path: "/solutions",
        children: [
          {
            path: "wireless-products",
            element: (
              <Suspense fallback={<PreLoader />}>
                <WirelessProducts />{" "}
              </Suspense>
            ),
          },
          {
            path: "switches&Devices",
            element: (
              <Suspense fallback={<PreLoader />}>
                <SwitchesAndDevices />
              </Suspense>
            ),
          },
          {
            path: "networkCabling",
            element: (
              <Suspense fallback={<PreLoader />}>
                <NetworkCabling />{" "}
              </Suspense>
            ),
          },
          {
            path: "fiberNetwork",
            element: (
              <Suspense fallback={<PreLoader />}>
                <FiberNetwork />{" "}
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense fallback={<PreLoader />}>
                <SolutionsData />{" "}
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense fallback={<PreLoader />}>
                <TimeAttendance />{" "}
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense fallback={<PreLoader />}>
                <VideoIntercom />{" "}
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense fallback={<PreLoader />}>
                <SmartSurveillance />{" "}
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense fallback={<PreLoader />}>
                <Storage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "webDesign",
        element: (
          <Suspense fallback={<PreLoader />}>
            <WebDesign />
          </Suspense>
        ),
      },

      {
        path: "solution/:id",
        element: (
          <Suspense fallback={<PreLoader />}>
            <SolutionsData />{" "}
          </Suspense>
        ),
      },
      {
        path: "admin",
        element: (
          <Suspense fallback={<PreLoader />}>
            <Admin />
          </Suspense>
        ),
        children: [
          {
            path: "/admin",
            element: <AdminProducts />,
          },
          {
            path: "/admin/category",
            element: <AdminCategory />,
          },
          {
            path: "/admin/products",
            element: <AdminProducts />,
          },
          {
            path: "/admin/partners",
            element: <AdminPartners />,
          },
          {
            path: "/admin/supports",
            element: <AdminEvent />,
          },
          {
            path: "/admin/contacts",
            element: <AdminContacts />,
          },
          {
            path: "/admin/product/:id",
            element: <ProductsEdit />,
          },
        ],
      },
      {
        path: "admin/login",
        element: (
          <Suspense fallback={<PreLoader />}>
            <Login />
          </Suspense>
        ),
      },
    ],
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
