import React, { useCallback, useEffect, useMemo, useState } from "react";
import SectionTitle from "../../utlis/SectionTitle";
import Button from "../../utlis/Button";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../state/CategorySlice";
import { getAllProducts } from "../../state/ProductsSlice";
import Loader from "../../utlis/Loader";
import AlertApi from "../../utlis/AlertApi";
import Pagination from "../../utlis/Pagination";
import AlertEmpty from "../../utlis/AlertEmpty";

const AllProducts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categories } = useSelector((state) => state.CategorySlice);
  const { products, loading, error } = useSelector(
    (state) => state.ProductsSlice
  );
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getAllProducts(currentPage));
  }, [dispatch, currentPage]);

  const handlePageChange = useCallback(
    (pageNumber) => {
      setCurrentPage(pageNumber);
      dispatch(getAllProducts(pageNumber));
    },
    [dispatch]
  );
  const memoizedCategories = useMemo(() => categories?.data, [categories]);

  const getCategoryName = useCallback(
    (productId) => {
      const productCategory = memoizedCategories?.find(
        (category) => category._id === productId
      );
      return productCategory ? productCategory.name : "Category Not Found";
    },
    [memoizedCategories]
  );

  const memoizedProducts = useMemo(() => products?.data, [products]);

  return (
    <div className="all-products section-m">
      <div className="title">
        <SectionTitle
          topTitleText={"PRODUCTS"}
          topTitleClasses={" text-center"}
          mainTitleText={"All Products"}
          mainTitleClasses={"text-center mb-4"}
        />
      </div>
      {loading ? (
        <Loader />
      ) : memoizedProducts?.length === 0 ? (
        <AlertEmpty />
      ) : error ? (
        <AlertApi />
      ) : (
        <div className="container-fluid">
          <div className="row gy-4">
            {memoizedProducts?.map((product) => (
              <div
                key={product._id}
                className="col-lg-3 col-md-4 col-md-6 col-12"
              >
                <div className="product">
                  <div className="img d-flex justify-content-center flex-column align-items-center ">
                    <img
                      loading="lazy"
                      src={product.imageCover}
                      alt=""
                      className="img-fluid"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/product/${product._id}`)}
                    />
                  </div>
                  <div className="text">
                    <Link to={`/product/${product._id}`}>
                      <h5>{product.title}</h5>
                    </Link>
                    <span>{getCategoryName(product.category)}</span>
                    <p className="mt-3 mb-3">{product.description}</p>
                  </div>
                  <div className="buttons d-flex align-items-center gap-3">
                    <Button href={`/product/${product._id}`}>More</Button>
                    <Button href={`/contact`}>Contact</Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {products?.paginationResult?.numberOfPages > 1 && (
        <Pagination
          numberOfPages={products?.paginationResult?.numberOfPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default AllProducts;
