import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
export const getAllSupports = createAsyncThunk(
  "supports/getAllSupports",
  async (page) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/event?limit=8&page=${page}`
    );
    const data = await response.json();
    return data;
  }
);
export const addSupport = createAsyncThunk(
  "support/addSupport",
  async (categoryData, thunkAPI) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/event`,
        categoryData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue("Failed to add product");
      }
    }
  }
);
export const deleteSupport = createAsyncThunk(
  "supports/deleteSupport",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}

/api/event/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
const initialState = {
  supportsData: [],
  loading: true,
  error: false,
};
const SupportsSlice = createSlice({
  name: "supports",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllSupports.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.supportsData = [];
    });
    builder.addCase(getAllSupports.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.supportsData = action.payload;
    });
    builder.addCase(getAllSupports.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(addSupport.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(addSupport.fulfilled, (state) => {
      state.loading = false;
      state.error = false;
    });
    builder.addCase(addSupport.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(deleteSupport.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(deleteSupport.fulfilled, (state) => {
      state.loading = false;
      state.error = false;
    });
    builder.addCase(deleteSupport.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});
export default SupportsSlice.reducer;
