// authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const login = createAsyncThunk(
  "auth/login",
  async (userData, { rejectWithValue }) => {
    try {
      const request = await axios.post(
        `${process.env.REACT_APP_API_URL}

/api/v1/auth/login/`,
        userData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const res = await request.data;
      return res;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const LoginSlice = createSlice({
  name: "auth",
  initialState: {
    userLogin: [],
    isLoading: false,
    error: false,
    isAuthenticated: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.userLogin = state.isAuthenticated ? action.payload : null;
        localStorage.setItem("token", action.payload.token);
      })
      .addCase(login.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export default LoginSlice.reducer;
