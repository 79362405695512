import React, { memo } from "react";
import AllProducts from "../../components/products/AllProducts";
import Breadcrumbs from "../../utlis/Breadcrumbs";

const Products = () => {
  return (
    <div className="products">
      <Breadcrumbs />
      <AllProducts />
    </div>
  );
};
export default memo(Products);
