import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
export const getAllContacts = createAsyncThunk(
  "contact/getAllContacts",
  async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}

/api/contact`);
    const data = await response.json();
    return data;
  }
);
export const createContact = createAsyncThunk(
  "contact/createContact",
  async (contactData, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}

/api/contact`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(contactData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create contact");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const deleteContact = createAsyncThunk(
  "contact/deleteContact",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}

/api/contact/${id}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
const initialState = {
  contacts: [],
  data: [],
  loading: true,
  error: false,
};
const ContactSlice = createSlice({
  name: "contact",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createContact.pending, (state) => {
        state.loading = true;
      })
      .addCase(createContact.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.data = action.payload;
      })
      .addCase(createContact.rejected, (state) => {
        state.loading = true;
        state.error = true;
      })
      .addCase(getAllContacts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllContacts.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.contacts = action.payload;
      })
      .addCase(getAllContacts.rejected, (state) => {
        state.loading = true;
        state.error = true;
      })
      .addCase(deleteContact.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteContact.fulfilled, (state) => {
        state.loading = false;
        state.error = false;
      })
      .addCase(deleteContact.rejected, (state) => {
        state.loading = true;
        state.error = true;
      });
  },
});
export default ContactSlice.reducer;
