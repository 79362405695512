import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getAllCategories } from "../../state/CategorySlice";
import { useParams } from "react-router-dom";
import { editProducts, getSingleProduct } from "../../state/ProductsSlice";

const ProductsEdit = () => {
  const { id } = useParams();
  // const [title, setTitle] = useState("");
  // const [category, setCategory] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState(0);
  // const [productDescription, setProductDescription] = useState("");
  // const [imageCover, setImageCover] = useState("");
  const dispatch = useDispatch();
  // const { categories } = useSelector((state) => state.CategorySlice);
  // const categoriesData = categories?.data;
  const { product } = useSelector((state) => state.ProductsSlice);

  useEffect(() => {
    // dispatch(getAllCategories());
    dispatch(getSingleProduct(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (product.data) {
      // setTitle(product.data.title);
      // setCategory(product.data.category);
      setQuantity(product.data.quantity);
      setPrice(product.data.price);
      // setProductDescription(product.data.description);
    }
  }, [product]);

  // const handleImage = (e) => {
  //   setImageCover(e.target.files[0]);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    // formData.append("title", title);
    // formData.append("category", category);
    formData.append("quantity", quantity);
    formData.append("price", price);
    // formData.append("description", productDescription);
    // formData.append("imageCover", imageCover);

    await dispatch(editProducts({ id: id, newData: formData })).then(() => {
      window.location.href = "/admin";
    });
  };

  return (
    <div className="edit-form">
      <h2>Edit Products</h2>
      <form onSubmit={handleSubmit}>
        {/* <div className="form-group">
          <label htmlFor="productName">Product Name</label>
          <input
            type="text"
            className="form-control"
            id="productName"
            placeholder="Product Title"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div> */}
        {/* <div className="form-group">
          <label htmlFor="category">Category</label>
          <select
            className="form-control"
            id="category"
            required
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="">Select a category</option>
            {categoriesData?.map((cat) => (
              <option key={cat._id} value={cat._id}>
                {cat.name}
              </option>
            ))}
          </select>
        </div> */}
        <div className="form-group">
          <label htmlFor="productQuantity">Quantity</label>
          <input
            type="number"
            className="form-control"
            id="productQuantity"
            required
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="productPrice">Price</label>
          <input
            type="number"
            className="form-control"
            id="productPrice"
            required
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
        {/* <div className="form-group">
          <label htmlFor="productDescription">Product Description</label>
          <textarea
            className="form-control"
            id="productDescription"
            rows="3"
            required
            value={productDescription}
            onChange={(e) => setProductDescription(e.target.value)}
          ></textarea>
        </div> */}
        {/* <div className="form-group">
          <input
            type="file"
            className="form-control-file mt-1"
            id="productImage"
            required
            onChange={handleImage}
          />
        </div> */}
        <button type="submit" className="btn mt-3 float-end">
          Save changes
        </button>
      </form>
    </div>
  );
};

export default ProductsEdit;
