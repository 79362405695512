import React from "react";
import Button from "../../utlis/Button";

const Error = () => {
  return (
    <div className="overflow-hidden ">
      <div className="row bg-danger ">
        <div className="col-md-12 ">
          <div className="error-template d-flex flex-column justify-content-center">
            <h1>Oops!</h1>
            <h2>404 Not Found</h2>
            <div className="error-details">
              <p> Sorry, an error has occured, Requested page not found!</p>
            </div>
            <div className="error-actions d-flex justify-content-center gap-4">
              <Button href={"/"}>Go Home</Button>

              <Button href={"/contact"}>Contact Support</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
