import React from "react";

const AlertApi = () => {
  return (
    <div className="alert alert-danger bg-danger text-center" role="alert">
      <strong>Oh No!</strong> We Are Working To solve This Problem come again in
      another time
    </div>
  );
};

export default AlertApi;
