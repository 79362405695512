import React from "react";
import { Link } from "react-router-dom";

const Button = ({ theType, classes, children, href }) => {
  return (
    <button
      type={`${theType ? theType : "button"}`}
      className={classes ? classes : ""}
    >
      <Link to={href}>{children}</Link>
    </button>
  );
};

export default Button;
