import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
  categories: [],
  categoriesAndSub: [],
  categoriesAdmin: [],
  singleCategory: [],
  loadingCategories: true,
  errorCategories: false,
};

export const getAllCategories = createAsyncThunk(
  "category/getAllCategories",
  async (limit) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}

/api/v1/categories?limit=${limit}`
    );
    return response.data;
  }
);

export const getAdminCategories = createAsyncThunk(
  "category/getAdminCategories",
  async (page) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}

/api/v1/categories?limit=4&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  }
);
export const addCategory = createAsyncThunk(
  "product/addProduct",
  async (categoryData, thunkAPI) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}

/api/v1/categories`,
        categoryData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue("Failed to add product");
      }
    }
  }
);
export const deleteCategory = createAsyncThunk(
  "category/deleteCategory",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}

/api/v1/categories/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
const CategorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCategories.pending, (state) => {
      state.loadingCategories = true;
    });
    builder.addCase(getAllCategories.fulfilled, (state, action) => {
      state.loadingCategories = false;
      state.errorCategories = false;
      state.categories = action.payload;
    });
    builder.addCase(getAllCategories.rejected, (state, action) => {
      state.loadingCategories = false;
      state.errorCategories = true;
      state.error = action.payload;
    });
    builder.addCase(getAdminCategories.pending, (state) => {
      state.loadingCategories = true;
      state.errorCategories = false;
    });
    builder.addCase(getAdminCategories.fulfilled, (state, action) => {
      state.loadingCategories = false;
      state.errorCategories = false;
      state.categoriesAdmin = action.payload;
    });
    builder.addCase(getAdminCategories.rejected, (state) => {
      state.loadingCategories = false;
      state.errorCategories = true;
    });

    builder.addCase(addCategory.pending, (state) => {
      state.loadingCategories = true;
    });
    builder.addCase(addCategory.fulfilled, (state) => {
      state.loadingCategories = false;
      state.errorCategories = false;
    });
    builder.addCase(addCategory.rejected, (state) => {
      state.loadingCategories = false;
      state.errorCategories = true;
    });
    builder.addCase(deleteCategory.pending, (state) => {
      state.loadingCategories = true;
    });
    builder.addCase(deleteCategory.fulfilled, (state) => {
      state.loadingCategories = false;
      state.errorCategories = false;
    });
    builder.addCase(deleteCategory.rejected, (state) => {
      state.loadingCategories = false;
      state.errorCategories = true;
    });
  },
});
export default CategorySlice.reducer;
