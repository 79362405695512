import React from "react";

const SectionTitle = ({
  topTitleText,
  topTitleClasses,
  mainTitleText,
  mainTitleClasses,
  plusClasses,
  plusText,
}) => {
  return (
    <div className="section-title mb-3 position-relative z-2 ">
      {topTitleText ? (
        <div className={`top-title ${topTitleClasses}`}>
          <span>{topTitleText}</span>
        </div>
      ) : (
        ""
      )}
      <div className={`main-title ${mainTitleClasses}`}>
        <h2>{mainTitleText}</h2>
      </div>
      {plusText ? (
        <div className={`plus ${plusClasses}`}>
          <p>{plusText}</p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SectionTitle;
