import React from "react";

const AlertEmpty = () => {
  return (
    <div className="alert alert-dark text-center" role="alert">
      <strong>Sorry!</strong> Soon we will add the contents
    </div>
  );
};

export default AlertEmpty;
