import React from "react";

const Loader = () => {
  return (
    <div className="w-100 d-flex b justify-content-center">
      <span className="loader text-center"></span>
    </div>
  );
};

export default Loader;
