import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
  partners: [],
  loading: true,
  error: false,
};
export const getAllPartners = createAsyncThunk(
  "partner/getAllPartners",
  async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/partner`
    );
    const data = await response.json();
    return data;
  }
);
export const addPartner = createAsyncThunk(
  "product/addPartner",
  async (partnerData, thunkAPI) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}

/api/partner`,
        partnerData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue("Failed to add product");
      }
    }
  }
);
export const deletePartner = createAsyncThunk(
  "partner/deletePartner",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/partner/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
const PartnerSlice = createSlice({
  name: "partner",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllPartners.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllPartners.fulfilled, (state, action) => {
      state.partners = action.payload;
      state.loading = false;
    });
    builder.addCase(getAllPartners.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });

    builder.addCase(addPartner.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addPartner.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addPartner.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(deletePartner.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletePartner.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePartner.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});
export default PartnerSlice.reducer;
