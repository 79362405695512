import { configureStore } from "@reduxjs/toolkit";
import CategorySlice from "./CategorySlice";
import ProductsSlice from "./ProductsSlice";
import PartnerSlice from "./PartnerSlice";
import SupportsSlice from "./SupportsSlice";
import LoginSlice from "./LoginSlice";
import ContactSlice from "./ContactSlice";
const store = configureStore({
  reducer: {
    CategorySlice,
    ProductsSlice,
    PartnerSlice,
    ContactSlice,
    SupportsSlice,
    LoginSlice,
  },
});
export default store;
