import React, { memo } from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumbs = () => {
  const location = useLocation();
  const path = location.pathname;
  const splitedPath = path.split("/");
  const firstLetter = splitedPath[1].split("")[0];
  const endPath = splitedPath[1]?.replace(
    firstLetter,
    firstLetter?.toUpperCase()
  );
  return (
    <div className="breadcrumbs position-relative">
      <div className="overlay"></div>
      <div className="container text-center">
        <h1>{endPath}</h1>
        <div className="path mt-2">
          <Link to={"/"}>Home</Link>
          <span>
            <svg
              height="1792"
              viewBox="0 0 1792 1792"
              width="1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1171 960q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23z" />
            </svg>
          </span>
          <span>{endPath}</span>
        </div>
      </div>
    </div>
  );
};

export default memo(Breadcrumbs);
