import React from "react";

const PreLoader = () => {
  return (
    <div id="preloadero" className=" overflow-hidden">
      <div id="loadero">
        <span className="loadero">
          <span className="loader-innero"></span>
        </span>
      </div>
    </div>
  );
};

export default PreLoader;
